.baseUILayerPanelContainer {
    position: relative;
    width: 100%;
    z-index: -1;
}

.baseUILayerPanel {
    padding: 0.2em 0.5em;
    padding-bottom: 1em;
    position: relative;
    bottom: -3em;
    cursor: default;
    color: white;
    background-color: rgba(0, 0, 0, 0.75);
}

.baseUILayerPanel p {
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    letter-spacing: 0px;
}

@media only screen and (min-width: 415px) {
    .baseUILayerPanel {
        width: 260px;
    }
}

@media only screen and (max-width: 415px) {
    .baseUILayerPanel {
        width: 100%
    }
}

.baseUILayerCloseIcon {
    float: right;
}

.product-button {
    height: 50%;
    padding: 0 0.7em;
    flex-grow: 1;
}

.product-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6em;
    width: 50%;
    margin: 0 auto;
    flex-shrink: 0;
}

.product-modal-footer-content {
    width: 100%;
    height: 2.25em;
}

.product-details-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.product-details-arrow {
    transition: transform 500ms ease;
    margin: 0 0.5em;
    font-weight: 700;
    width: 15px;
}

.cart-item-container {
    display: flex;
    margin: 1em;
}

.cart-item-thumbnail {
    width: 92px;
    height: 92px;
    object-fit: contain;
    margin: 1em 3em 0 0.5em;
}

.cart-item-break {
    border-color: rgba(34,36,38,.15);
    border-width: 0.5px;
    width: 4.6em;
    margin: 0 0 0.5em 2em;
}

.cart-item-info {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    font-family: 'Source Sans Pro';
    font-weight: 1;
    color: black;
}

.cart-item-remove {
    cursor: pointer;
    font-size: 12px;
    color: #717171;
    margin-right: 1em;
}
.cart-item-remove:hover {
    color: #000000
}
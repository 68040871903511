.linked-store-scene-border {
    border-color: rgba(34,36,38,.15);
    border-width: 0.5px;
    width: 4em;
    margin: 1em 0;
}

span.linked-store-scene {
    color: #000000;
}

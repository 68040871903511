.product-modal-details-container {
    height: 0%;
    transition: opacity 400ms;
    pointer-events: none;
    text-align: left;
    text-align: start;
    font-family: 'Source Sans Pro';
    margin: 0 0.5em;
}

.product-modal-header {
    margin: 0.75em 0 0.5em;
    font-size: 21px;
}

.product-modal-content {
    font-weight: normal;
    font-size: 14px;
}

ul.product-modal-content {
    padding: 0 0 0 1em;
}
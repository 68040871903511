.thumbnail-indicator-container, .thumbnail-indicator-container-selected {
    /*min-height: 23.9%;
    min-width: 10%; */
    /*width: 3em;
    height: 4em;*/
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    min-height: 4em;
    min-width: auto;
    border: 1px solid #979797;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.thumbnail-indicator-container {
    opacity: .7;
}

.thumbnail-indicator-icon {
    height: 100%;
    width: 80%;
    object-fit: contain;
}

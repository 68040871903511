#bottom-bar-selector-container {
    position: fixed;
    height: 3.25em;
    z-index: 10;
    display: flex;
    text-align: center;
}

.bottom-bar-selector-item-start, .bottom-bar-selector-item, .bottom-bar-selector-item-locked {
    border-style: solid;
    border-color: white;
}
.bottom-bar-selector-item, .bottom-bar-selector-item-locked {
    border-width: 2px 2px 2px 0;
}

.bottom-bar-selector-item-start {
    border-width: 2px;
}

.bottom-bar-selector-item-start, .bottom-bar-selector-item {
    background-color: #856d6d;
}

.bottom-bar-selector-item-locked {
    background-color: #00000066;
}

.bottom-bar-selector-lock-icon {
    width: 12px;
    margin-left: 3px;
    opacity: 80%;
}

.locked-selector-text, .glowing-selector-text {
    text-transform: capitalize;
    color: white;
    font-size: 14px;
    letter-spacing: 1px;
}

.locked-selector-text {
    opacity: 80%;
}

.glowing-selector-text {
    text-shadow: none;
}
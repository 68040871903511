#product-image-carousel, #product-image-carousel-desktop {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#product-image-carousel-desktop {
    flex-direction: row-reverse; 
}

.product-image-wrapper {
    display: flex;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}

.carousel-indicator-container-mobile {
    display: flex;
    justify-content: center;
    margin-top: 0.5em;
}

.carousel-indicator-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 15%;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.carousel-indicator-container::-webkit-scrollbar {
    display: none;
}

.thumbnail-carousel-arrow-container {
    height: 10px;
    width: 12.5%;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 4%;
}

.thumbnail-carousel-arrow-container:last-child {
    top: 87.25%;
}

.thumbnail-carousel-arrow-up, .thumbnail-carousel-arrow-down {
    content: '';
    height: inherit;
    width: 10px;
    border-top: 1px solid black;
    border-right: 1px solid black;
}

.thumbnail-carousel-arrow-up {
    position: absolute;
    top: 3px;
    transform: rotate(315deg);
}

.thumbnail-carousel-arrow-down {
    position: absolute;
    top: -3px;
    transform: rotate(135deg);
}

.product-image-container {
    width: 100%;
    height: 100%;
}

#carousel-arrow-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    pointer-events: none;
}

#carousel-right-arrow, #carousel-left-arrow {
    width: 50px;
    pointer-events: all;
    transition: all 250ms ease;
}

#carousel-right-arrow:hover, #carousel-left-arrow:hover {
    cursor: pointer;
}

#carousel-left-arrow {
    margin: 0 auto 0 0;
    transform: rotate(180deg);
}

#carousel-right-arrow {
    margin: 0 0 0 auto;
}

#carousel-right-arrow-ipad {
    width: 50px;
    pointer-events: all;
    transition: all 250ms ease;
    margin: 0 55px 0 auto;
}

@media only screen and (max-width: 415px) {
    #carousel-right-arrow, #carousel-left-arrow {
        width: 25px;
    }

    #carousel-right-arrow {
        margin: 0 55px 0 auto;
    }
}
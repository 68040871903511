.main-modal-container {
    display: flex;
    width: 45em;
    height: 600px;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-modal-container .content-container{
    width: 100%;
    padding: 1.5em 3em;
    text-align: center;
    color: black;
    font-family: 'Sofia Pro Regular Az';
    display: flex;
    flex-direction: column;
}

.main-modal-container .content-container .scene-rotation-instruction, .navigation-arrow-instruction, .section-selector-instruction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    margin: 1em 0;
}

.main-modal-container .modalContentWrapperCloseButton {
    top: -0.2em;
    right: -0.2em;
}


.main-modal-container img {
    width: 20em;
    height: auto;
    margin: 10px 0;
}


.modalWrapper.main-modal-container{
    text-align: center;
}

@media only screen and (max-width: 435px) {
    .main-modal-container {
        width: auto;
        height: auto;
    }
    
    .main-modal-container .content-container .scene-rotation-instruction, .navigation-arrow-instruction, .section-selector-instruction {
        font-size: 14px;
        flex-direction: column;
        margin: 30px 0 20px 0;
    }

    .main-modal-container .content-container{
        padding: 0 1em;
    }

    .main-modal-container img {
        width: 100%;
    }

    span {
        width: 100%;
    }
}
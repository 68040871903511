#ObsessPromoPageContainerOuter {
    z-index: 999;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    font-size: 3em;
    font: OpenSans-Bold, sans-serif;
    font-weight: bold;
    color: white;
    background-color: rgba(0,0,0,0.2);
    pointer-events:none;
}

#ObsessPromoPageContainerInner {
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    pointer-events:all;
}

#ObsessPromoPageContainerOuter p {
    color: white;
}

.ObsessPromoPageTitleText {
    margin: 0 auto;
    text-align: center;
    word-wrap: break-word;
}

@media only screen and (max-width: 415px) {
    .ObsessPromoPageTitleText {
        max-width: 90%;
    }
}

@media only screen and (min-width: 415px) {
    .ObsessPromoPageTitleText {
        max-width: 50%;
    }
}

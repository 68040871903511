body {
    touch-action: none;
    /* Need this to prevent elastic scrolling */
    position: fixed;
    /* overflow: hidden; */
}

#obsessvr-virtual-store-embed-inner-app-container {
    overflow: hidden;
}

#obsessvr-virtual-store-embed-inner-app-container,
#obsessvr-webstore-react-embed-root {
    position: relative;
}

#obsessvr-virtual-store-embed-inner-app-container,
#obsessvr-webstore-react-embed-root{
    margin: 0;
    width: 100%;
    height: 100%;
}

.flex {
    display: flex;
    flex-direction: column;
}

.align-bottom {
    margin-top: auto;
}

.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-left {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.float-left {
    float: left;
}

.text-align-left {
    text-align: left;
}

.flex-no-shrink {
    flex-shrink: 0;
}

.horizontal-center {
    margin-left: auto;
    margin-right: auto;
}

.obsessvr-boldFont {
    font-family: Helvetica, sans-serif;
    font-weight: bold;
}

.obsessvr-regularFont {
    font-family: Helvetica, sans-serif;
    font-weight: normal;
}

.pointer {
    cursor: pointer;
}

.inline {
    display: inline;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.arrow-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.arrow-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.align-right {
    text-align: right;
    /*display: inline-block;*/
}

#obsessvr-webstore-react-embed-root canvas {
    position: absolute;
    z-index: 1;
}

#obsessvr-webstore-react-embed-root .hoverable {
    cursor: all-scroll;
}

#obsessvr-webstore-react-embed-root .hoverable:hover {
    cursor: pointer;
}

#obsessvr-webstore-react-embed-root .overflow-auto {
    overflow-y: auto;
}

.full-width {
    width: 100%;
}
.zoomedImageContainer {
    height: 100% !important;
}
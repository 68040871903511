body,html{
   height:100%
}
html{
   /* font-size:14px */
}
body{
   margin:0;
   padding:0;
   overflow-x:hidden;
   min-width:320px;
   background:#fff;
   font-family:'';
   /* font-size:14px; */
   /* line-height:1.4285em; */
   color:rgba(0,0,0,.87);
   font-smoothing:antialiased
}
h1,h2,h3,h4,h5{
   font-family:'';
   /* line-height:1.28571429em; */
   margin:calc(2rem - .14285714em) 0 1rem;
   font-weight:700;
   padding:0
}
h1{
   min-height:1rem;
   font-size:2rem
}
h2{
   font-size:1.71428571rem
}
h3{
   font-size:1.28571429rem
}
h4{
   font-size:1.07142857rem
}
h5{
   font-size:1rem
}
h1:first-child,h2:first-child,h3:first-child,h4:first-child,h5:first-child{
   margin-top:0
}
h1:last-child,h2:last-child,h3:last-child,h4:last-child,h5:last-child{
   margin-bottom:0
}
p{
   margin:0 0 1em;
   line-height:1.4285em
}
p:first-child{
   margin-top:0
}
p:last-child{
   margin-bottom:0
}
a{
   color:#4183c4;
   text-decoration:none
}
a:hover{
   color:#1e70bf;
   text-decoration:none
}
::-webkit-selection{
   background-color:#cce2ff;
   color:rgba(0,0,0,.87)
}
::-moz-selection{
   background-color:#cce2ff;
   color:rgba(0,0,0,.87)
}
::selection{
   background-color:#cce2ff;
   color:rgba(0,0,0,.87)
}
input::-webkit-selection,textarea::-webkit-selection{
   background-color:rgba(100,100,100,.4);
   color:rgba(0,0,0,.87)
}
input::-moz-selection,textarea::-moz-selection{
   background-color:rgba(100,100,100,.4);
   color:rgba(0,0,0,.87)
}
input::selection,textarea::selection{
   background-color:rgba(100,100,100,.4);
   color:rgba(0,0,0,.87)
}
body ::-webkit-scrollbar{
   -webkit-appearance:none;
   width:10px;
   height:10px
}
body ::-webkit-scrollbar-track{
   background:rgba(0,0,0,.1);
   border-radius:0
}
body ::-webkit-scrollbar-thumb{
   cursor:pointer;
   border-radius:5px;
   background:rgba(0,0,0,.25);
   -webkit-transition:color .2s ease;
   transition:color .2s ease
}
body ::-webkit-scrollbar-thumb:window-inactive{
   background:rgba(0,0,0,.15)
}
body ::-webkit-scrollbar-thumb:hover{
   background:rgba(128,135,139,.8)
}
body .ui.inverted::-webkit-scrollbar-track{
   background:rgba(255,255,255,.1)
}
body .ui.inverted::-webkit-scrollbar-thumb{
   background:rgba(255,255,255,.25)
}
body .ui.inverted::-webkit-scrollbar-thumb:window-inactive{
   background:rgba(255,255,255,.15)
}
body .ui.inverted::-webkit-scrollbar-thumb:hover{
   background:rgba(255,255,255,.35)
}

.carousel-indicator {
    width: 8px;
    height: 8px;
    background-color: #D8D8D8;
    border-radius: 100%;
    margin: 0.25em 0.15em;
}

.carousel-indicator-selected {
    background-color: #999999;
}
#obsessvr-webstore-react-embed-root .modalContainer {
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

#obsessvr-webstore-react-embed-root .modalWrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-wrap: break-word;
    display: inline-block;
}

.modalContentWrapperCloseButton {
    border-radius: 50%;
    width: 2.8em;
    height: 2.8em;
    position: absolute;
    top: -1.4em;
    right: -1.4em;
    float: right;
    background: yellow;
    z-index: 10;
}

#obsessvr-webstore-react-embed-root .modalContentWrapper {
    /*max-height: inherit;*/
}

@media only screen and (max-width: 415px) {
    #obsessvr-webstore-react-embed-root .modalWrapper {
        /*width: 80%;*/
    }
}

@media only screen and (min-width: 415px) {
    #obsessvr-webstore-react-embed-root .modalWrapper {
        /*width: 35em;*/
    }
}


#storeSelector {
    color: black;
    position: relative;
    z-index: 10;
    top: 0;
    right: 0.5em;
    margin-bottom: auto;
    text-align: center;
}

#storeSelectorMenu {
    margin-top: 0.3em;
    background-color: rgb(255, 255, 255);
    width: 13.5em;
    right: 0;
    position: absolute;
    overflow-y: auto;
    max-height: 70vh;
}

#storeSelectorMenuTitle {
    padding: 0 1em;
}

#selectorTip {
    text-align: right;
    position: absolute;
    width: 100px;
    margin: auto;
    display:inline-block;
    transform: translateX(-100%);

}

#selectorTipLabel {
    color: white !important;
    padding:2px;
    background-color: black;

}  

.section-title {
    margin-top: 0.2em;
    font-size: 14px;
    font-weight: 600;
}
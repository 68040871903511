.social-button-container {
    width: 60px;
    height: 60px;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0.6em 0 0 0.8em;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    opacity: 80%;
}

.social-button-icon-container {
    height: 25px;
    margin: 0 0 0.25em;
}

.social-button-icon {
    width: auto;
    height: 25px;
}

.social-button-text {
    font-size: 12px;
    height: 12px;
}

.social-button-active {
    background: white;
    color: black;
}

.social-button-invert-color {
    filter: invert(1);
}
.text-modal-container {
    background: white;
}

.modalWrapper.text-modal-image-container {
    max-width: 45%;
    background: white;
}

.product-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6em;
    width: 75%;
    margin: 0 auto;
    flex-shrink: 0;
}

.text-modal-content {
    margin: 20px 50px 50px;
}

.text-modal-close-button-container {
    margin: 0 auto;
}

.text-modal-text-container {
    margin: 0 40px 30px;
    max-height: 300px;
    padding: 0 10px;
}

.text-modal-img-container img{
    max-height: 300px;
}

@media screen 
and (max-width: 428px) {
    .text-modal-text-container {
        margin: 0 0 30px;
        width: 100%;
        max-width: 100%;
    }
    .modalWrapper.text-modal-image-container {
        max-width: 85%;
    }
    .text-modal-text-container {
        max-height: 200px;
    }
    .text-modal-content{
        padding: 0px;
    }
}

@media screen 
  and (max-width: 374px) {
    .text-modal-img-container img{
        max-height: 200px;
    }
}


@media screen and (min-width: 720px) and (max-width: 1280px) {
    .modalWrapper.modalWrapper.text-modal-container  {
    width: 80%;
    max-width: 80%;
    height: 80%;
    max-height: none;
    }

    .text-modal-content {
        max-height: 20em;
        overflow-y: auto;
    }
}


@media screen and (min-width: 960px) and (max-width: 1152px) {
    .text-modal-content {
        max-height: 30em;
    }
 
}

#add-to-cart-arrow {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
    margin-left: 10px;
}

#add-to-cart-menu {
    margin-top: 0;
    margin-right: 0.5em;
    position: fixed;
    right: 0;
    width: 23em;
    background: white;
    border-radius: 5px;
    border-style: none;
}

.cart-dropdown-item-container {
    width: auto;
    max-height: 455px;
    overflow-y: auto;
    /* -webkit-overflow-scrolling: touch; */
}

@media only screen and (max-width: 415px) {
    #add-to-cart-menu {
        width: 95vw;
    }
}
#bottomBarLeft {
    position: fixed;
    z-index: 10;
    bottom: 0.1em;
    /* TODO: UNCOMMENT WHEN SWITCHING OVER NEW STYLE OF SOCIAL BUTTONS */
    /* bottom: 0.6em; */
    display: flex;
}

#social-button-wrapper {
    display: flex;
}

@media only screen and (max-width: 415px) {
    _::-webkit-full-page-media, _:future, :root #bottomBarLeft {
        bottom: 0.5em;
    }
}
#screenshot-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 44px;
    padding: 0px;
    width: 90%;
    font-size: 20px;
    box-shadow: 0px 2px 4px 0px #777777;
    background-color: #EA5A67;
    color: white;
    border: none;
    font-family: 'Raleway', 'Roboto', sans-serif;
    border-radius: 0.2em;
}

#screenshot-button-container:hover {
    cursor: pointer;
}

#screenshot-button-text {
    margin: 0 1.5em;
    font-size: 22px;
}

.screenshot-button-icon {
    width: 1.5em;
    height: 1.5em;
    margin: 0 0.5em;
}

.screenshot-button-icon:hover {
    cursor: pointer;
}

.screenshot-button-icon {
    cursor: pointer;
}
#share-panel-container {
    position: absolute;
    bottom: 4.5em;
    height: 12em;
    width: 24em;
    background-color: white;
    color:black;
    font-family: Helvetica;
    left: 0.8em;
}

#title-separation-line {
    border-bottom: 1px black solid;
    width: 7em;
    margin-top: 0.8em;
}

#share-panel-tag {
    margin: 1.6em 0 0;
    text-decoration: none !important;
    font-size: 13px;
    color: black;
    font-weight: normal;
}

#share-panel-social-icon-container {
    display: flex;
    margin: 1.5em 0 0;
}

#share-panel-triangle-connector {
    /* triangle properties */
    width: 0; 
    height: 0; 
    border-left: 0.7em solid transparent;
    border-right: 0.7em solid transparent;
    border-top: 1em solid white;
    /* end triangle properties */
    position: absolute;
    left: 1.15em;
    top: 11.68em;
}

@media only screen and (min-width: 415px) {
    #share-panel-container {
        width: 24em;
    }
}

@media only screen and (max-width: 415px) {
    #share-panel-container {
        width: 93.5vw;
    }
}

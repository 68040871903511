.cart-button-container {
    /* margin-right: ; */
    width: 50px;
    margin: 0 1.5em 0 auto;

}

.cart-icon-container {
    width: 2.3125em;
    height: 2.3125em;
}

.cart-icon {
    width: 2em;
    height: 2.325em;
    filter: invert(1);
}

.cart-button-text {
    margin-top: 0.35em;
    font-size: 14px;
    font-weight: 600;
    /* font-family: 'Source Sans Pro'; */
    color: black;
}
.share-text-above {
    position: absolute;
    top: 11.25em;
    font-size: 12px;
    letter-spacing: 0.28px;
    transition: all 400ms;
    font-family: 'Montserrat';
    color: black;
    font-weight: normal;
    left: 25.9em;
    width: 50px;
    text-align: center;
}

.instagram-wrapper {
    border: 1px solid black;
    border-radius: 4px;
    padding: 7px 7px 4px 7px;
    margin-right: 5px;
    margin-left: 4px;
}

/* .icon-bg {
    background-image: url("https://cdn.obsess-vr.com/instagram-transparent-icon.png");
} */

@media only screen and (max-width: 410px) {
    .share-text-above {
        left: 24.6em;
    }
}

.modalWrapper.iframe-modal-container {
    width: 50%;
}

.iframe-modal-container .modalContentWrapperCloseButton {
    top: -0.4em;
}

@media only screen and (max-width: 767px){
    #obsessvr-webstore-react-embed-root .modalWrapper {
        width:80%;
    }

    .iframeContrainer iframe{
        height:400px!important;
    }
}

#tipContainer {
    padding: 1em 0.5em;
}

#poweredByObsess {
    bottom: 0;
}

/* #poweredAndDisclaimer {
    margin-bottom: -0.15em;
} */

#poweredAndDisclaimer div {
    display: inline-block;
    color: white;
}

#poweredAndDisclaimer p {
    color: white;
}

#separator {
    padding: 0 0.35em;
}

.tip {
    display: flex;
    margin: 1em auto;
}

.tipIcon {
    width: 15%;
    height: 100%;
    text-align: center;
}

.tipText {
    width: 85%;
    padding-left: 13px;
}

@media only screen and (max-width: 415px) {
    .tipText {
        padding: 1em;
    }
}

#user-panel-container {
    position: absolute;
    bottom: 4.5em;
}

#user-panel-center {
    width: 18em;
    margin: 0 auto;
    background: white;
    text-align: center;
    padding: 0.3em 0;
    opacity: 80%;
    border-radius: 3px;
    position: relative;
    left: 14px;
}

#user-panel-text {
    font-size: 12px;
    color: black;
    font-weight: normal;
}
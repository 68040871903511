.text-modal-container {
    width: 25%;
    background: white;
}

/* .text-modal-content {
    margin: 35px 20px 20px;
} */

/* .text-modal-subtitle-container {
    margin: 45px 0 0 0;
} */

.text-modal-close-button-container {
    margin: 0 auto;
}

.text-modal-html-container {
    min-width: 80%;
}

.text-modal-html-heading {
    font-family: futura-light-bt;
    font-size: 20px;
}

.text-modal-carousel {
    display: flex;
    max-height: 32em;
    /* overflow-y: auto; */
    overflow-x: hidden;
}

.text-modal-html-container p {
    font-family: Futura-Book-Regular;
    line-height: 1.4em;
}

.text-modal-arrow {
    pointer-events: none;
}

.text-modal-arrow img {
    width: 2.5em;
}

.left-arrow {
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    z-index: 111;
    top: 50%;
    left: 15px;
    margin-top: -10px;
    pointer-events: all;
    transition: all 250ms ease;
    height: 40px;
    width: 40px;
    overflow: hidden;
}

.right-arrow {
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    z-index: 111;
    top: 50%;
    right: 20px;
    margin-top: -10px;
    pointer-events: all;
    transition: all 250ms ease;
    height: 40px;
    width: 40px;
    overflow: hidden;
}
.subtitle{
    padding-top: 5px;;
}

.text-modal-title-container{
    padding-bottom: 32px;
}
@media only screen and (max-width: 415px) {
    .text-modal-container {
        width: 110%;
    }
    .text-modal-html-container {
        margin: 0 0 30px;
    }
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) {
    .text-modal-container {
        width: 85%;
    }
    .text-modal-title-container span {
        font-size: 30px !important;
    }
}

#topBarRight {
    position: relative;
    float: right;
    /*width: 12em;*/
    z-index: 10;
    top: 0.5em;
    /*padding-right: 1em;*/
}

#topBarRightAtBottomLeft {
    position: fixed;
    z-index: 10;
    bottom: 0.1em;
    left: 0.5em;
}

#bottomBarRight {
    position: fixed;
    z-index: 10;
    bottom: 0.25em;
    right: 0;
    font-weight: 400;
    font-size: 12px;
}

#bottomBarRightMenuContainer {
    float: right;
    margin-right: 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#bottomBarRightMenuContainer div {
    display: inline-block;
    color: white;
}

#bottomBarRightMenuContainer p {
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    letter-spacing: 0px;    
}

.uiHighlight p {
    font-weight: bolder;
    color: white;
}

#bottomBarObsessLogoWrapper {
    position: relative;
    bottom: -0.2em;
}

@media only screen and (max-width: 415px) {
    .composableModalWrapper {
        max-width: 85%;
    }
}

@media only screen and (min-width: 415px) and(max-width: 650px) {
    .composableModalWrapper {
        max-width: 80%;
    }
}

@media only screen and (min-width: 651px) {
    .composableModalWrapper {
        max-width: 70vw;
    }
}

#imageContentWrapper {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    padding: 20px;
}

#imageContentWrapper img {
    max-height: 100%;
    max-width: 100%;
    /* width: auto !important; */
    cursor: default;
}

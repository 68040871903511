#topLeftLogo {
    position: absolute;
    top: 0.8em;
    left: 0.8em;
    float: left;
    z-index: 10;
    display: block;
}

@media only screen and (min-width: 415px) {
    #topLeftLogo {
        width: 25%;
        max-width: 315px;
    }
}

@media only screen and (max-width: 415px) {
    #topLeftLogo {
        width: 170px;
    }
}
